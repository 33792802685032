import React from 'react';

interface ContainerProps {
  className?: string;
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className={`container py-2 sm:py-6 p-8 mx-auto xl:px-0 ${props.className ? props.className : ''}`}
    >
      {props.children}
    </div>
  );
};

export default Container;
