export interface StripePlan {
  price: number;
  maxSketches: number;
}

export interface StripePlans {
  Small: StripePlan;
  Basic: StripePlan;
  Pro: StripePlan;
}

export const siteConfig = {
  title: 'AI Profile Picture Generator | SketchMe.App',
  description:
    "Create stunning AI-powered profile pictures from your selfies. Stand out on social media with SketchMe.App's instantly generated, unique portraits.",
  url: 'https://www.sketchme.app',
  maxAllowedFiles: 3,
  maxAllowedFileSize: 4 * 1024 * 1024,
  numGenerateSketchApiCall: 2,
  initialNumberOfSketchesRemaining: 4,
  requestTimeOut: 4 * 60 * 1000,
  stripePlans: {
    Small: {
      price: 900,
      maxSketches: 24,
    },
    Basic: {
      price: 1500,
      maxSketches: 48,
    },
    Pro: {
      price: 2400,
      maxSketches: 96,
    },
  } as StripePlans,
  apiQueueWaitingTime: 40, // seconds
  midjourneyAccountIdxList: [
    [0, 1], // 0
    [0, 1], // 1
    [0, 1], // 2
    [0, 1], // 3
    [0, 1], // 4
    [0, 1], // 5
    [0, 1], // 6
    [2, 3], // 7
    [2, 3], // 8
    [2, 3], // 9
    [2, 3], // 10
    [2, 3], // 11
    [2, 3], // 12
    [2, 3], // 13
    [2, 3], // 14
    [2, 3], // 15
    [2, 3], // 16
    [2, 3], // 17
    [2, 3], // 18
    [0, 1], // 19
    [0, 1], // 20
    [0, 1], // 21
    [0, 1], // 22
    [0, 1], // 23
  ], // midjourneyAccountIdx by hour of the day
};

export const underRepair = false;
