import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import { Disclosure } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';

import { underRepair } from '@/constant/config';
import { menus } from '@/constant/LandingPage/menus';

interface NavbarProps {
  handleUnderRepair: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ handleUnderRepair }) => {
  return (
    <div className='w-full'>
      <nav className='container relative flex flex-wrap items-center justify-between p-6 sm:p-8 mx-auto lg:justify-between xl:px-0'>
        {/* Logo  */}
        <Disclosure>
          {({ open }) => (
            <>
              <div className='flex flex-wrap items-center justify-between w-full lg:w-auto'>
                <Link href='/'>
                  <span className='flex items-center space-x-2 text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-bold text-gold-400'>
                    <span>
                      <Image
                        src='/svg/Icon.svg'
                        alt='N'
                        width='64'
                        height='64'
                        style={{ height: 'auto' }}
                        className='w-9 sm:w-10 xl:w-12'
                      />
                    </span>
                    <span>SketchMe.App</span>
                  </span>
                </Link>

                <Disclosure.Button
                  aria-label='Toggle Menu'
                  className='px-2 py-1 ml-auto text-gray-500 rounded-md lg:hidden hover:text-gold-500 active:text-gold-500 active:bg-gold-100 active:outline-none dark:text-gray-300 dark:active:bg-trueGray-700'
                >
                  <svg
                    className='w-6 h-6 fill-current'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                  >
                    {open && (
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z'
                      />
                    )}
                    {!open && (
                      <path
                        fillRule='evenodd'
                        d='M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z'
                      />
                    )}
                  </svg>
                </Disclosure.Button>

                <Disclosure.Panel className='flex flex-wrap w-full my-5 lg:hidden'>
                  <>
                    {menus.map((item, index) => (
                      <Link
                        key={index}
                        href={item.href}
                        className='w-full px-4 py-2 -ml-4 text-gray-500 rounded-md dark:text-gray-300 hover:text-gold-500 active:text-gold-500 active:bg-gold-100 dark:active:bg-gray-800 active:outline-none'
                      >
                        {item.name}
                      </Link>
                    ))}

                    {underRepair ? (
                      <button
                        className='w-full px-6 py-2 mt-3 text-center text-white bg-gray-500 rounded-md lg:ml-5'
                        onClick={handleUnderRepair}
                      >
                        Sign In
                      </button>
                    ) : (
                      <SignInButton mode='modal'>
                        <button className='w-full px-6 py-2 mt-3 text-center text-white bg-gray-500 rounded-md lg:ml-5'>
                          Sign In
                        </button>
                      </SignInButton>
                    )}

                    {underRepair ? (
                      <button
                        className='w-full px-6 py-2 mt-3 text-center text-white bg-gold-600 rounded-md lg:ml-5'
                        onClick={handleUnderRepair}
                      >
                        Sign Up
                      </button>
                    ) : (
                      <SignUpButton mode='modal'>
                        <button className='w-full px-6 py-2 mt-3 text-center text-white bg-gold-600 rounded-md lg:ml-5'>
                          Sign Up
                        </button>
                      </SignUpButton>
                    )}
                  </>
                </Disclosure.Panel>
              </div>
            </>
          )}
        </Disclosure>

        {/* menu  */}
        <div className='hidden text-center lg:flex lg:items-center'>
          <ul className='items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex'>
            {menus.map((item, index) => (
              <li className='mr-3 nav__item' key={index}>
                <Link
                  href={item.href}
                  className='inline-block lg:px-2 xl:px-4 py-2 xl:text-lg font-normal text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-gold-500 active:text-gold-500 active:bg-gold-100 active:outline-none dark:active:bg-gray-800'
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className='hidden mr-3 space-x-4 lg:flex nav__item'>
          {underRepair ? (
            <button
              className='px-6 py-2 text-white bg-gray-500 rounded-md'
              onClick={handleUnderRepair}
            >
              Sign In
            </button>
          ) : (
            <SignInButton mode='modal'>
              <button className='px-6 py-2 text-white bg-gray-500 rounded-md'>
                Sign In
              </button>
            </SignInButton>
          )}
          {underRepair ? (
            <button
              className='px-6 py-2 text-white bg-gold-400 rounded-md'
              onClick={handleUnderRepair}
            >
              Sign Up
            </button>
          ) : (
            <SignUpButton mode='modal'>
              <button className='px-6 py-2 text-white bg-gold-400 rounded-md'>
                Sign Up
              </button>
            </SignUpButton>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
